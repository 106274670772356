import React from 'react'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'

import { connect } from 'react-redux'
import { theme } from '../util/style'
import { setLightboxForm } from '../actions'

import PageHeroGallery from '../components/page-hero-gallery'
import PageButtons from '../components/page-buttons'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import PageOverview from '../components/page-overview'

const ogImages = [`etc/happyhaus-gallery-2023-09-30-photo-fingal-1030655.jpg`]

const HeroImage1 = 'etc/happyhaus-gallery-2023-09-30-photo-fingal-1030655.jpg'

const slides = [
  {
    image: HeroImage1,
  },
]

const mapDispatchToProps = (dispatch) => ({})

const Page = ({ dispatch }) => {
  const isClosed = true

  const registerForm = {
    category: 'Open Haus Pre-registration',
    title: 'Pre-register for Open Haus',
    redirect: '/open-haus-thank-you',
    form: 'open-haus',
  }
  const subscribeForm = {
    category: 'Open Haus Subscribe Form',
    title: 'Open Haus Subscribe Form',
    redirect: '/open-haus-thank-you',
    form: 'open-haus-subscribe',
  }
  const handleClick = () => {
    dispatch(setLightboxForm(isClosed ? subscribeForm : registerForm))
  }

  const openCopy = (
    <p>
      We invite you to come and see it for yourself and talk with the team about
      what makes a Happy Haus.
      <br />
      <br />
      <b>Last chances to visit Open Haus.</b>
      <br />
      <br />
      <b>Address</b>
      <br />
      12 Fingal Street, Tarragindi{' '}
      <a
        target="_blank"
        href="https://www.google.com/maps/place/12+Fingal+St,+Tarragindi+QLD+4121/data=!4m2!3m1!1s0x6b915af16d281c79:0x4859353be3d57790?sa=X&ved=2ahUKEwjcpoXegsWCAxWNB4gKHRCiDZUQ8gF6BAgKEAA"
      >
        (map)
      </a>
      <br />
      <br />
      <b>Times</b>
      <br />
      Saturday, 2 December 2 – 5pm <br />
      Sunday, 10 December 2 – 5pm
      <br />
      <br />
      Note: Registration will also be required upon arrival and a limited number
      of visitors will be allowed into Open Haus at once. Please consider our
      neighbours when parking in the street.
    </p>
  )

  const closedCopy = (
    <>
      <p>
        Please register your interest to receive information about Open Haus in
        the future.
      </p>
      <PageButtons
        align="left"
        data={[
          {
            label: isClosed ? 'Register' : 'Pre-Register',
            onClick: handleClick,
            background: theme.colors.grey1,
          },
        ]}
      />
    </>
  )

  return (
    <>
      <SEO
        title="Open Haus Tarragindi"
        description="Haus Tarragindi is a masterfully crafted home with the perfect balance of design and connection to nature. Experience this modern abode that showcases a suite of premium finishes, stunning landscaping and a seamless blend of materials."
        images={ogImages}
      />
      <Row>
        <Flex flexWrap="wrap" flexDirection={['column', 'row']}>
          <Box width={[1, 6 / 12]} px={2}>
            <PageOverview>
              <p>
                Haus Tarragindi is a masterfully crafted home with the perfect
                balance of design and connection to nature. Experience this
                modern abode that showcases a suite of premium finishes,
                stunning landscaping and a seamless blend of materials.
              </p>
            </PageOverview>
          </Box>
          <Box width={[1, 6 / 12]} px={2} ml={[0]}>
            <Copy>{isClosed ? closedCopy : openCopy}</Copy>
          </Box>
        </Flex>
      </Row>
      <PageHeroGallery slides={slides} ratio={9 / 16} />
    </>
  )
}

export default connect(mapDispatchToProps)(Page)
